
import { Component, Vue, Prop } from "vue-property-decorator";
import AttachmentIcon from "@/components/AttachmentIcon.vue";

import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";

@Component({
  components: { AttachmentIcon },
})
export default class SearchResultRow extends Vue {
  @Prop() item?: BrainLibraryDTO;

  onClickTitle(brainLibrary: BrainLibraryDTO): void {
    this.$router.push({
      name: "PostDetails",
      params: {
        libraryType: brainLibrary.subtype
          ? brainLibrary.subtype.toLowerCase()
          : brainLibrary.type.toLowerCase(),
        postTitle: brainLibrary.title,
        postId: brainLibrary._id,
      },
    });
  }
}
