
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PageTitleBar from "@/components/PageTitleBar.vue";
import AttachmentIcon from "@/components/AttachmentIcon.vue";
import PostDetailsPagination from "@/components/PostDetailsPagination.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import SearchResultRow from "@/components/SearchResultRow.vue";
import VsPagination from "@vuesimple/vs-pagination";

import { BreadCrumbDTO } from "@/interfaces/BreadCrumb.dto";
import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";

@Component({
  components: {
    PageTitleBar,
    AttachmentIcon,
    PostDetailsPagination,
    BreadCrumbs,
    SearchResultRow,
    VsPagination,
  },
})
export default class SearchResult extends Vue {
  @Prop() searchQuery?: string;
  mapLibraryTypeToPathProp = {
    INVESTMENT_STRATEGY: "investment_strategy",
    ALTERNATIVE_INVESTMENTS: "alternative_investments",
    MARKET_UPDATE: "market_update",
    MANAGEMENT_CARDS: "market_update",
  };

  breadCrumbItems: Array<BreadCrumbDTO> = [];

  searchResultItems: Array<BrainLibraryDTO> = [];

  currentPagePagination = 1;
  totalPagesPagiantion = 0;

  @Watch("searchQuery")
  onSearchQueryChange(): void {
    this.refresh();
  }

  mounted(): void {
    this.refresh();
    this.createBreadCrumbItem();
  }

  async refresh(): Promise<void> {
    try {
      const { data } = await this.$api.get("/library/library-access", {
        params: {
          page: this.currentPagePagination,
          title: this.searchQuery,
        },
      });
      this.searchResultItems = data.records;
      this.totalPagesPagiantion = data.pages || 1;
    } catch (error) {
      console.error(error);
    }
  }

  changePage(page: number): void {
    if (this.currentPagePagination != page) {
      this.currentPagePagination = page;
      this.refresh();
    }
  }

  createBreadCrumbItem(): void {
    this.breadCrumbItems = [
      {
        text: "Search Result",
        goToPath: "",
        clickable: false,
      },
    ];
  }
  get searchResultMessage(): string {
    return this.searchQuery
      ? "Search Result for:"
      : "Enter text to perform a search...";
  }
}
