
import { Component, Vue, Prop } from "vue-property-decorator";
import { BrainLibraryDTO } from "@/interfaces/BrainLibrary.dto";

@Component({
  components: {},
})
export default class PostDetailsPagination extends Vue {
  @Prop() brainLibrary?: BrainLibraryDTO;

  onClick(brainLibrary: BrainLibraryDTO): void {
    this.$router.push({
      name: "PostDetails",
      params: {
        libraryType: brainLibrary.subtype
          ? brainLibrary.subtype.toLowerCase()
          : brainLibrary.type.toLowerCase(),
        postTitle: brainLibrary.title,
        postId: brainLibrary._id,
      },
    });
  }
}
